import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sub-workorder-container"
  }, [_c("section", {
    staticClass: "sub-workorder-top"
  }, [_c("a-form-model", {
    attrs: {
      layout: "inline",
      model: _vm.queryParams
    }
  }, [_c("a-form-model-item", [_c("a-textarea", {
    attrs: {
      placeholder: "".concat(_vm.$t("postReport.reporterID"), "\uFF0C").concat(_vm.$t("common.multipleRowsByEachID")),
      "auto-size": {
        minRows: 2,
        maxRows: 3
      }
    },
    model: {
      value: _vm.queryParams.usreids,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "usreids", $$v);
      },
      expression: "queryParams.usreids"
    }
  })], 1), _c("a-form-model-item", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(_vm._s(_vm.$t("common.search")))]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: _vm.resetSearchField
    }
  }, [_vm._v(_vm._s(_vm.$t("common.reset")))])], 1)], 1)], 1), _c("section", {
    staticClass: "sub-workorder-content"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      pagination: false,
      rowKey: "id",
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "username",
      fn: function fn(username, _ref) {
        var fansnum = _ref.fansnum,
          usertype = _ref.usertype;
        return [_c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("common.nickname")))]), _vm._v("："), usertype > 0 ? _c("img", {
          staticStyle: {
            position: "relative",
            top: "-2px",
            "margin-right": "2px"
          },
          attrs: {
            src: _vm.certified,
            alt: ""
          }
        }) : _vm._e(), _vm._v(_vm._s(username) + " ")]), _c("p", {
          staticStyle: {
            "margin-top": "5px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.fans")) + "："), _c("span", {
          style: _vm.setFensNumStyle(fansnum)
        }), _vm._v(_vm._s(fansnum) + " ")])];
      }
    }, {
      key: "memo",
      fn: function fn(memo, _ref2) {
        var updatetime = _ref2.updatetime;
        return [_c("span", [_vm._v(_vm._s(updatetime) + " ")]), _c("span", [_vm._v(_vm._s(memo))])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };